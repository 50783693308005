import { useEffect, useRef, useState } from 'react';
import CareGemConsentForm from './CaregemConsent';
import RpmConsentForm from './RPMConsent';
import './style.css';
import SignatureCanvas from 'react-signature-canvas';
import { Checkbox } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface ConsentProps {
  selectedConsent?: string;
  setConsentName?: (flag: any) => void;
  setByteString?: (flag: any) => void;
  setSignerType?: (flag: any) => void;
  setSignType?: (flag: any) => void;
  setPatientExtId?: (flag: any) => void;
  selectedUser?: any;
  byteString?: any;
}

const Consents: React.FC<ConsentProps> = ({
  selectedConsent,
  setConsentName,
  setByteString,
  setSignerType,
  setSignType,
  setPatientExtId,
  selectedUser,
  byteString,
}) => {
  const SignatureCanvasRef = useRef<SignatureCanvas | null>(null);
  const [PDFValue, setPDFValue] = useState('');
  const [checkBoxValue, setCheckBoxValue] = useState('');
  const [isSignatureValidState, setIsSignatureValidState] = useState(true);

  const handleCheckboxChange = (value: string) => {
    setCheckBoxValue(value);
  };

  useEffect(() => {
    if (selectedUser) {
      setConsentName && setConsentName(selectedConsent);
      setByteString && setByteString(PDFValue);
      setSignerType && setSignerType(checkBoxValue);
      setSignType && setSignType('image');
      setPatientExtId && setPatientExtId(selectedUser.external_id);
    }
  }, [selectedUser, PDFValue, checkBoxValue]);

  const Clear = () => {
    setPDFValue('');
    setByteString && setByteString('');
    setIsSignatureValidState(true);
    SignatureCanvasRef.current?.clear();
  };

  const validateSignature = () => {
    if (SignatureCanvasRef.current) {
      const signatureDataURL = SignatureCanvasRef.current.toDataURL();
      const isValid = signatureDataURL.length > 10000;
      setIsSignatureValidState(isValid);
    }
  };

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      className="consentWrapper"
    >
      {selectedConsent === 'caregem' ? (
        <CareGemConsentForm
          sign={PDFValue}
          selectedUser={selectedUser}
          checkBoxValue={checkBoxValue}
        />
      ) : (
        <RpmConsentForm
          sign={PDFValue}
          selectedUser={selectedUser}
          checkBoxValue={checkBoxValue}
        />
      )}

      <div style={{ marginTop: 140, width: '94%' }}>
        <label style={{ fontWeight: '600' }}>Please select signer :</label>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>
            <Checkbox
              checked={checkBoxValue === 'Patient'}
              onChange={() => handleCheckboxChange('Patient')}
            />
            Patient
          </label>
          <label>
            <Checkbox
              checked={checkBoxValue === 'Guardian'}
              onChange={() => handleCheckboxChange('Guardian')}
            />
            Guardian
          </label>
          <label>
            <Checkbox
              checked={checkBoxValue === 'Family member'}
              onChange={() => handleCheckboxChange('Family member')}
            />
            Family member (signing on behalf of the patient)
          </label>
        </div>
      </div>
      <div style={{ marginTop: '20px', width: '94%' }}>
        <label style={{ fontWeight: '600' }}>
          Please draw your signature below:
        </label>
        <div
          className="signature-container"
          style={{
            borderColor: isSignatureValidState ? '' : 'red',
          }}
        >
          <div
            style={{
              marginLeft: '10px',
              marginTop: '5px',
              fontSize: 10,
              fontWeight: 100,
              color: '#979dab',
              width: '96%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {!isSignatureValidState && (
              <span style={{ color: 'red', position: 'absolute' }}>
                Please draw a valid signature.
              </span>
            )}
            <DeleteIcon
              style={{
                color: 'red',
                fontSize: '24px',
                zIndex: 9999,
                marginLeft: '99%',
                cursor: 'pointer',
                visibility: !PDFValue ? 'hidden' : 'visible',
              }}
              onClick={Clear}
            />
          </div>
          <SignatureCanvas
            ref={SignatureCanvasRef}
            penColor="#1890ff"
            canvasProps={{
              className: 'sigCanvas',
            }}
            onEnd={() => {
              setPDFValue(
                SignatureCanvasRef.current!.getTrimmedCanvas().toDataURL(),
              );
              validateSignature();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Consents;
