import React, { useState } from 'react';
import {
  AppBar,
  Typography,
  Menu,
  Button,
  MenuItem,
  Tabs,
  Tab,
  Badge,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CAREGEM_LOGO from 'images/caregem-logo.png';
import { Link, useNavigate } from 'react-router-dom';
import './CaregemNavBar.css';
import { FormattedMessage } from 'react-intl';
import I18nKey from 'lib/I18nKeys';
import { NavRoutes } from 'pages/NavRouter';
import isEmpty from 'lodash/isEmpty';
import Divider from './Divider';
import { createApiClient } from 'api/apiClient';
import { useQuery } from 'react-query';

interface CaregemNavBarProps {
  readonly handleLogout: () => void;
  readonly username: string;
}

export enum AdditionalRouteLabel {
  surveyreport = 'Survey Reports',
  billingReports = 'Billing Reports',
  remoteMonitoringReports = 'Remote Monitoring Reports',
  exceptions = 'Exceptions',
  RPMDeviceOrders = 'RPM Device Orders',
  RPMEligiblePatient = 'RPM Eligible Patients',
}

export const getInitialPath = (pathName: string) =>
  pathName === '/' ? NavRoutes.patients : pathName;

const TWENTY_FOUR_HOURS = 1000 * 60 * 60 * 24;

const CaregemNavBar: React.FC<CaregemNavBarProps> = ({
  handleLogout,
  children,
  username,
}) => {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem('access_token');
  const controller = new AbortController();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [value, setValue] = useState<string>(
    getInitialPath(window.location.pathname),
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLDivElement>) =>
    setAnchorElUser(event.currentTarget);

  const handleCloseUserMenu = () => setAnchorElUser(null);

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: NavRoutes,
  ) => {
    if (newValue === NavRoutes.reports) {
      return;
    } else {
      setValue(newValue);
    }
  };

  const handleMenuItemClick = (path: string) => {
    path.replace('/', '').toLowerCase() ===
    AdditionalRouteLabel.exceptions.toLowerCase()
      ? navigate(path)
      : window.open(path, '_blank');
    handleCloseUserMenu();
  };

  const settings = [
    {
      label: AdditionalRouteLabel.surveyreport,
      path: NavRoutes.surveyreport,
    },

    {
      label: AdditionalRouteLabel.billingReports,
      path: NavRoutes.billingReports,
    },
    {
      label: AdditionalRouteLabel.remoteMonitoringReports,
      path: NavRoutes.remoteMonitoringReports,
    },
    { label: AdditionalRouteLabel.exceptions, path: NavRoutes.exceptions },
    {
      label: AdditionalRouteLabel.RPMDeviceOrders,
      path: NavRoutes.RPMDeviceOrders,
    },
    {
      label: AdditionalRouteLabel.RPMEligiblePatient,
      path: NavRoutes.RPMEligiblePatient,
    },
  ];

  const { data: exceptions } = useQuery(
    'topbar-exceptions-count',
    async () => createApiClient(accessToken!, controller).getExceptions(),
    { staleTime: TWENTY_FOUR_HOURS, cacheTime: TWENTY_FOUR_HOURS },
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: '#90caf9',
          paddingTop: '10px',
        }}
      >
        <div className="appBarTitle">
          <div>
            <Typography variant="h6" noWrap>
              <img
                alt=""
                src={CAREGEM_LOGO}
                width="110"
                height="40"
                className="logo-image"
              />
              <FormattedMessage id={I18nKey.ORG_PORTAL_VERSION_LABEL} />
            </Typography>
          </div>
          <div>
            <div className="usernameText">{username}</div>
            <Button color="inherit" onClick={handleLogout}>
              {<FormattedMessage id={I18nKey.LOGOUT} />}
            </Button>
          </div>
        </div>
        <div className="centerTabs">
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="inherit"
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              label={<FormattedMessage id={I18nKey.NAVBAR_LABEL_PATIENTS} />}
              value={NavRoutes.patients}
              to={NavRoutes.patients}
              component={Link}
            />
            <Tab
              label={<FormattedMessage id={I18nKey.NAVBAR_LABEL_CAREGIVERS} />}
              value={NavRoutes.caregivers}
              to={NavRoutes.caregivers}
              component={Link}
            />
            <Divider />
            <Tab
              label={<FormattedMessage id={I18nKey.NAVBAR_LABEL_PHYSICIANS} />}
              value={NavRoutes.physicians}
              to={NavRoutes.physicians}
              component={Link}
            />
            <Tab
              label={<FormattedMessage id={I18nKey.NAVBAR_LABEL_NURSES} />}
              value={NavRoutes.nurses}
              to={NavRoutes.nurses}
              component={Link}
            />
            <Tab
              label={
                <FormattedMessage id={I18nKey.NAVBAR_LABEL_CASEMANAGERS} />
              }
              value={NavRoutes.caseManagers}
              to={NavRoutes.caseManagers}
              component={Link}
            />
            <Tab
              label={
                <FormattedMessage id={I18nKey.NAVBAR_LABEL_SOCIAL_WORKERS} />
              }
              value={NavRoutes.socialworkers}
              to={NavRoutes.socialworkers}
              component={Link}
            />
            <Tab
              label={<FormattedMessage id={I18nKey.NAVBAR_LABEL_DIETICIAN} />}
              value={NavRoutes.dietician}
              to={NavRoutes.dietician}
              component={Link}
            />
            <Tab
              label={<FormattedMessage id={I18nKey.NAVBAR_LABEL_OTHERS} />}
              value={NavRoutes.others}
              to={NavRoutes.others}
              component={Link}
            />
            <Divider />
            <Tab
              label={<FormattedMessage id={I18nKey.NAVBAR_LABEL_ARCHIVED} />}
              value={NavRoutes.archived}
              to={NavRoutes.archived}
              component={Link}
            />
            <Divider />
            <Tab
              value={NavRoutes.reports}
              disableRipple={false}
              label={
                <div>
                  <div color="inherit" onClick={handleOpenUserMenu}>
                    <Badge
                      invisible={isEmpty(exceptions)}
                      color="error"
                      variant="dot"
                    >
                      <MenuIcon fontSize="large" />
                    </Badge>
                  </div>
                  <Menu
                    className="menuContainer"
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map(setting => (
                      <MenuItem
                        key={setting.label}
                        onClick={() => {
                          handleMenuItemClick(setting.path);
                        }}
                      >
                        {setting.label === AdditionalRouteLabel.exceptions ? (
                          <div className="flex">
                            <Typography textAlign="center">
                              {setting.label}
                            </Typography>
                            <Badge
                              invisible={isEmpty(exceptions)}
                              className="badgeSidePosition"
                              color="error"
                              variant="dot"
                            />
                          </div>
                        ) : (
                          <Typography textAlign="center">
                            {setting.label}
                          </Typography>
                        )}
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              }
            />
          </Tabs>
        </div>
      </AppBar>
      <div className="pageContainer">{children}</div>
    </>
  );
};
export default CaregemNavBar;
